.project-search-files
  overflow-x: hidden
  input
    max-width: 100%
  select
    max-width: 250px
    display: inline-block
    font-family: monospace
  button
    margin-right: 5px
  .search-option
    vertical-align: middle
    margin-left: 5px
    display: inline-block

  .results-container
    padding: 0
    padding-bottom: half-vertical-rhythm

  .results-list
    padding-bottom: 0
  .result
    white-space: nowrap
    overflow: hidden
  .results-name
    display: inline-block
    cursor: pointer
  .result-column-wrap
    min-width: 30px
    margin-right: 5px
  .nested-result
    margin-left: 10px
  .nested-result-wrap
    display: flex
  .status-badge
    .status
      margin-left: 0
      margin-right: 5px
  .result-code
    max-height: 90px
    overflow: hidden
    white-space: pre-wrap
  .result-tip
    vertical-align: middle
  .musical-keyboard
    vertical-align: -4px
  .loader
    width: 16px
    height: 16px

  .no-search-results-illustration
    background-image: url(no-search-results)
    height: 32vh
    width: 100%
    background-size: contain
    background-repeat: no-repeat
    background-position: center
