.truncated-text
  white-space: nowrap
  display: flex
  overflow: hidden
  min-width: 25px

.truncated-text-center-segment
  overflow: hidden
  text-overflow: ellipsis
  min-width: 9px
