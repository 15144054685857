// inherits from pop-overs.styl

.git-import-export-pop
  bottom: 23px
  left: 2px
  top: initial
  .loader
    height: 21px
    width: 21px
  .write-container
    margin-top: vertical-rhythm
    margin-bottom: vertical-rhythm
  .input-wrap
    margin-bottom: 0
