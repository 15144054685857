.rewind-panel
  position: relative
  background: primary-background
  min-height: 200px

  header
    background: primary-background

  button
    .icon
      margin: 0

  .loading-rewinds
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

  .loading-revision
    position: absolute
    right: 0
    top: -12px
    .loader
      width: 21px
      height: 20px

  .anon-avatar
    background-size: 18px
    background-position: center 9px

  .users
    margin-top: 25px

  .person-container
    position: absolute
    right: 1px
    bottom: 0
    .person
      display: block
      padding-left: 5px
    .rewind-icon
      opacity: 1
      width: 100%
      height: 100%
      background-position: 5px center

  .timeline
    position: relative

  playhead, .playhead
    display: flex
    align-items: center
    flex-direction: column
    position: absolute
    right: var(--rewind-revision-width)
    width: 42px
    margin-right: -22px
    height: 100%
    cursor: ew-resize
    background-size: contain

    .playhead-arrow
      width: 19px
      height: 24px

    .tail
      flex: 1
      width: 3px
      background-color: rewind-playhead

  .rewind-graph
    user-select: none
    overflow: auto
    height: 100%
    max-width: 100vw

  .rewind-columns-container
    display: flex
    flex-direction: row-reverse
    position: relative
    min-height: 100%
    min-height: -webkit-fill-available
    margin-left: auto
    
  .rewind-column
    width: var(--rewind-revision-width)
    cursor: pointer
    position: relative
    flex-shrink: 0
    padding-bottom: 16px

    &:hover,
    &.highlighted
      background-color: secondary-background-hover
      .time
        .time-label-year-show-on-hover,
        .time-label-monthday-show-on-hover
          opacity: 1

    &:active
      background-color: secondary-background-active

    .cell
      height: 50px
      display: flex
      position: relative
      align-items: center

    .time
      align-items: flex-end
      height: 59px
      border-bottom: 1px solid section-line
      padding: 0 6px

      .time-label
        font-size: 12px
        margin: 0 1px 2px
        user-select: none

      .time-label-year, .time-label-monthday, .time-label-time
        display: block

      .time-label-year-show-on-hover,
      .time-label-monthday-show-on-hover
        opacity: 0

      .time-label-year, .time-label-monthday
        font-weight: bold
        margin-bottom: 4px

    .revisions
      display: flex
      flex-direction: column
      justify-content: flex-end
      width: 100%
      height: 100%
      border-radius: 4px

      .rewind-file
        margin: 0 1px
        background-color: secondary
        cursor: pointer

      .logic
        background-color: file-logic
      .data
        background-color: file-data
      .info
        background-color: file-info
      .view
        background-color: file-view
      .style
        background-color: file-styles
      .other
        background-color: file-other
      .image
        background-color: file-image

    .git-revision
      background-color: pink
      height: 100%
      width: 100%
      .git-label
        position: absolute
        border: 1px solid text-on-secondary-background
        color: text-on-secondary-background
        border-radius: 3px
        padding: 0 2px
        font-size: 11px
        bottom: 0
        right: 0

  .commit-message
    margin-left: 20px
    border-radius: 5px
    height: 25px
    padding: 0 8px
    display: inline-flex
    align-items: center
    justify-content: center
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 50%
    font-size: 14px
    line-height: 1

  .commit-message-filled
    background-color: section-line

  .instructions-container
    margin-top: 1rem
    position: absolute
    top: 0
    left: 0
    pointer-events: none
    .instructions
      font-size: 14px
      margin-left: side-padding
      margin-right: 4px
      display: inline-block
      max-width: 35%
      vertical-align: middle
