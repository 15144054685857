// inherits from pop-overs.styl

.custom-domain-pop
  bottom: 23px
  left: 2px
  top: initial
  .bold
    font-weight: 600
  .flyio
    padding-left: 5px
    width: 16px
