.debugger-page
  padding-top: 11px
  display: block

  h1
    font-size: 14px
    display: inline-block

  h2
    font-size: 14px
    font-weight: 600

  p
    font-size: 14px

  header
    padding: 0
    margin: 0

  main,
  section
    margin-top: 1rem

  header,
  main,
  section
    padding-left: side-padding
    padding-right: side-padding

  .glitch-logo
    display: inline-block
    background-repeat: no-repeat
    margin-right: 5px

  .code-container
    position: relative

  .copy
    position: absolute
    right: 0
    top: 0

  .warning
    p
      display: inline-block

  .info-container
    border-radius: 5px
    background-color: secondary-background
    padding: 12px
    color: text-on-secondary-background
    h2
      margin-top: 0
    p
      margin-bottom: 0

  .info-container,
  .code-container
    max-width: 600px

  .button-cta
    margin-top: 1rem

  .copy-notification
    background-color: success

