// inherits from pop-overs.styl

.ask-for-help-pop
  top: -1px
  left: 4px
  position: relative
  font-family: sans-serif
  .read-only
    background-color: transparent
    border-color: transparent
  .check-env
    input
      float: left
    p
      margin-left: 19px

  .ask-for-help-pop-loader
    margin-bottom: 12px

  .secrets-sidebar-icon
    sidebar-item-with-icon()
    secrets-file-icon()
    background-position: initial
    margin: 0

  .onboarding-tip
    margin-top: vertical-rhythm
