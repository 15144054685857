.container-stats-panel
  overflow: auto
  z-index: 0
  padding-bottom: 20px

  header
    z-index: 2
  
  section
    display: grid
    grid-template-columns: repeat(auto-fill,minmax(300px, 1fr))
    grid-gap: 20px
    padding: 10px 20px
