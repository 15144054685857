// inherits from pop-overs.styl

.projects-list-loading
  display: flex
  justify-content: center
  margin: 12px 0

.switch-project-sticky-header
  position: sticky
  top: 0
  z-index: 100

.project-select-pop .result .private-icon
  background-position-y: -1px
  margin-right: 5px
