// inherits from overlays.styl

.keyboard-shortcuts-overlay
  .key
    display: inline-block
    border-radius: 3px
    background-repeat: no-repeat
    background-position: center
    height: 25px
    vertical-align: middle
    padding-left: 8px
    padding-right: 8px
    margin-right: 10px
    line-height: 28px
    margin-bottom: 2px
    background-color: keyboard-shortcut
  .shortcut
    font-family: monospace
    font-size: 13px
    line-height: 34px
    .shortcuts
      display: inline-block
