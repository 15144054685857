.console-panel
  .console-container
    width: 100%
    height: calc(100% - 43px)
    padding-left: side-padding
    background-color: rgb(16, 16, 16)

  .console-frame
    border: none
    width: 100%
    height: 100%
