.notifications
  z-index: 11
  top: 9px
  right: 10px
  position: absolute
  margin-left: 10px

  .notification
    background-color: notification
    padding: 5px 8px
    border-radius: 5px
    max-width: 220px
    animation-name: hideme
    animation-delay: 2.5s
    animation-duration: 0.1s
    animation-iteration-count: 1
    animation-direction: forward
    animation-fill-mode: forwards
    animation-timing-function: ease-out
    margin-bottom: 5px
    color: primary-background
    font-size: 12px
    font-weight: 600
    line-height: 16px

    p
      color: primary-background
      font-size: 12px
      font-weight: 600
      line-height: 16px
      margin-top: 0
      margin-bottom: half-vertical-rhythm
      &:last-child,
      &:only-child
        margin-bottom: 0

    hr
      opacity: 0.5
      height: 1px
      border: 0
      background-color: primary-background
    .loader
      width: 20px
      height: 19px
      margin: 0
      margin-right: 5px

    button,
    .button
      button-small()
      margin-right: 5px
      padding-bottom: 1px

    .button-wrap
      pointer-events: auto
      margin-top: 5px
      margin-bottom: 2px
      &:first-child
        margin-top: 2px
      :last-child
        margin-right: 0
      &.cta-container
        padding-bottom: 4px

    .close
      position: absolute
      transform: none
      right: 0px
      padding: 0
      height: 20px
      width: 20px
      margin-right: 5px

    &.notification-close 
      padding-right: 25px

  .notification-unstyled
    max-width: 220px
    animation-name: hideme
    animation-delay: 2.5s
    animation-duration: 0.1s
    animation-iteration-count: 1
    animation-direction: forward
    animation-fill-mode: forwards
    animation-timing-function: ease-out
    margin-bottom: 5px

  // custom notification overrides

  .notifyCopied,
  .notifyAutosave,
  .notifyPackageUpdated,
  .notifyPackageAdded,
  .notifyPreviewWindowOpened,
  .notifyJoinedProject,
  .notifyJoinedProjectAsHelper,
  .notifyEditorRewoundProject,
  .notifyCanEdit,
  .notifyCanEditInEmbedRemixedEmbed
    background-color: success

  .notifyReconnected,
  .notifyJoinedProject,
  .notifyJoinedProjectAsHelper,
  .notifyWelcomeImportedProjectUser,
  .notifyGithubImportSuccess,
  .notifyGithubExportSuccess
    background-color: success
    animation-delay: 5s

  .notifyAutosave
    padding-right: 13px

  .notifyDebuggerIsChromeOnly
    background-color: error

  .notifyGithubEmptyRepositoryError,
  .notifyGithubRepositoryNotFoundError,
  .notifyUploadFailure,
  .notifyInvalidGitRepository,
  .notifyGenericError
    background-color: error
    animation-delay: 10s
    user-select: text

  .notifyConnectionError, 
  .notifyConnectionNotEstablished,
  .notifyFileHiddenByGitIgnore,
  .notifyGithubExportFailure
    background-color: error
    animation: none

  .notifyGithubImporting,
  .notifyGithubExporting,
  .notifyAnonUserLimits,
  .notifyAnonProjectLimits,
  .notifyTakeActionToEdit,
  .notifyEditorIsEmbedRemixing,
  .notifyEditorIsPreviewingRewind,
  .notifyEditorIsRewindingProject,
  .notifyCanNowUseCustomDomains,
  .notifyOpenInFullEditor,
  .notifyCanEditInEmbedRemixedEmbed,
  .notifyMarkdownCSPError,
  .notifyPrettierFirstRun,
  .notifyGithubExportNoRepoScopeError
    animation: none

  // use secondary background color for persistent state notifications
  .notifyTakeActionToEdit,
    background-color: secondary

  .notifyCopyFailure
    animation-delay: 5s

  .notifyInvalidFileName
    background-color: error
    animation-delay: 5s
    max-width: 285px

  .notifyUploading
    animation: none
    assets-file-icon-active()
    background-repeat: no-repeat
    background-position: 8px 5px
    padding-left: 28px

  .notifyprojectIsReadOnlyForCurrentUser
    width: 135px

  .notifyReconnecting
    animation: none
    .loader-ellipses
      display: inline-block
      padding: 0
      width: 10px

  .notifyProjectSave
    background-color: secondary

  .notifyDeletedProject
    animation-delay: 6s
    .deleted-project-avatar
      border-radius: 3px
      display: inline-block
      width: 18px
      vertical-align: middle
      margin-left: 5px

  .notifyPrettierParseError,
  .notifyPrettierLoadError
    background-color: error

  .rewind-tape-container
    position: relative
    width: 30px
    display: inline-block
    margin-right: 5px
    vertical-align: middle
    .rewind-tape-reel
      animation: rotate-tape-reel 1s infinite linear
    &.rewound-tape
      .rewind-tape-reel
        ease-out-quart = cubic-bezier(0.165, 0.84, 0.44, 1)
        animation: rotate-tape-reel-rewound 5s ease-out-quart

  .notifyCloneError
    animation: none
    background-color: error

  .notifyUploadLocalFileOnly
    animation-delay: 5s
    background-color: error

@keyframes hideme
  from
    opacity: 1
  to
    opacity: 0

@keyframes rotate-tape-reel
  to
    transform: rotate(-360deg)

@keyframes rotate-tape-reel-rewound
  20%
    transform: rotate(-1800deg)
  100%
    transform: rotate(-1801deg)
