// inherits from overlays.styl

.new-stuff-overlay
  .new-stuff-doggo
    width: 50px
    height: 53px
    background-size: cover
    margin: 0
    float: left
    margin-right: 10px
  .button
    margin-top: 5px
  h2
   background-color: warning-background
  img
    border-radius: 5px
    border: 1px solid input-border
    max-width: 100%
  code
    font-family: monospace
    background-color: secondary-background-active
    font-size: 13px
  li
    margin-bottom: 5px
    background-position: center
    background-repeat: no-repeat
    list-style-type:square
