.share-link-generator
  .loader
    height: 21px
    width: 21px

  .private-icon,
    background-position-y: -1px
    margin-right: 5px

  .is-private
    background-color: private
    border-radius: 3px
    margin-right: 4px
    padding: 10px
    background-position: center
    margin-top: -3px

  .share-link
    width: calc(100% - 36px)
    display: block
    overflow: hidden
    white-space: nowrap
