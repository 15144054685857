.error-page
  background-color: black
  color: white
  font-family: menlo
  font-weight: bold
  padding-left: 15px
  padding-right: 15px
  font-size: 80%
  position: relative
  max-width: 100%

  .container
    margin-top: calc(20%)
    text-align: center
    max-width: 425px
    margin-left: auto
    margin-right: auto

  h1
    margin-bottom: 2rem

  h2
    font-weight: normal

  .actions
    margin-top: 3em

    .button
      font-family: sans-serif

    .support-link
      color: warning

  @media(max-width: 500px)
    font-size: 70%

  #stars
    position: absolute
    top: 0
    left: 0
    z-index: -1

  .glitch-logo
    width: 60px
    height: 60px
    background-repeat: no-repeat
    margin-left: auto
    margin-right: auto
