.advanced-options
  display: flex
  align-items: center
  flex-wrap: wrap

  > .button-wrap
    background-color: line-on-secondary-background
    border-radius: 6px
    margin: 8px 5px 0 0
    display: inline-block
    position: relative

    &.boosted
      background: linear-gradient(12deg, #C454FF, #2800FF, #FA8A7C, #FE7DAB)

    > button
      button-small()
      button-secondary()
      border: none
      margin: 1px;
      white-space: nowrap

  .pop-over
    max-height: calc(88vh - 70px)
