.project-navigators
  .button-wrap
    max-width: 100%
    margin: 0
    margin-right: 5px
    display: inline-block
    position: relative
    .segmented-buttons
      segmented-buttons()
      button
        button-small()
        button-secondary()

  .left-arrow,
  .right-arrow,
  .close-icon
    margin: 0
    dulled-secondary()

  .close-icon
    width: 10px
    height: 10px

  .search-box
    truncate()
    
  .search-box-value
    max-width: calc(100% - 32px)
    truncate()
