.full-screen-sparkle-effect
  position: fixed
  top: var(--editor-top-position, 49px)
  left: var(--editor-left-position, 250px)
  width: var(--editor-width)
  height: var(--editor-height)
  z-index: 6
  pointer-events: none
  user-select: none
  overflow: hidden

  canvas
    width: 100%
    height: 100%
