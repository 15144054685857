.overlay-background
  background-color: overlay-background
  width: 100%
  height: 100%
  position: fixed
  top: 0
  left: 0
  z-index: 10
  cursor: default
  display: flex
  align-items: center
  justify-content: center

.overlay
  font-size: 14px
  margin: 0
  cursor: initial
  box-shadow: pop-shadow
  z-index: 10
  padding: 0
  -webkit-overflow-scrolling: touch
  overflow-y: auto
  background-color: primary-background
  color: primary
  border: 2px primary solid
  border-radius: 5px
  max-width: 640px
  width: 94%
  max-height: 90%
  height: initial
  overflow-y: auto
  display: block
  position: relative
  section
    color: primary
    padding: vertical-rhythm
    padding-top: 14px
    padding-bottom: 14px
    position: relative
    border-bottom: 1px solid secondary
    &:first-child
      border-top-left-radius: 3px
      border-top-right-radius: 3px
    &:last-child
      border-bottom: none
      border-bottom-left-radius: 3px
      border-bottom-right-radius: 3px

    p
      color: primary
      margin-bottom: vertical-rhythm
      margin-top: 0
      position: relative
      &:last-child,
      &:only-child
        margin-bottom: 0
      a:not(.button)
        color: general-link
      a:hover
        text-decoration: none
      img
        max-width: 100%
    h1
      display: inline-block
      font-weight: bold
      font-size: 14px
      margin: 0
      vertical-align: -1px

    h2
      margin: 0
      margin-bottom: vertical-rhythm
      display: inline-block
      font-weight: bold
      font-size: 14px
      .emoji
        cursor: default
        margin: 0
        margin-right: 5px
    a:not(.button)
      color: general-link
    a:hover
      text-decoration: none

    .input-wrap
      margin-bottom: vertical-rhythm
      position: relative
      &:last-child,
      &:only-child
        margin-bottom: 0
      input
        margin: 0
      .search-input
        search-input()
      select.search-input
        -moz-appearance: none
        -webkit-appearance: none
        down-arrow-in-input()

      input[type="checkbox"]
        margin-right: 5px

  .results-list
    results-list()

  input,
  .input
    pop-over-input()

  section.info
    background-color: secondary-background
    button,
    .button
      button-small()
      button-secondary-on-secondary-background()
    .segmented-buttons
      segmented-buttons()
      button
        button-secondary-on-secondary-background()
    .button-copy, .button-copy-only-style
      button-copy()


  section.actions
    background-color: primary-background
    button,
    .button
      button-large()
    .segmented-buttons
      segmented-buttons()
      button
        button-secondary()
    .button-copy, .button-copy-only-style
      button-copy()
    .button-small
      button-small()
    .button-secondary
      button-secondary()


  section.danger-zone
    background-color: warning-background
    button
      button-small()
      button-secondary-on-secondary-background()

.overlay-narrow
  max-width: 500px
