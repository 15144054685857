.body-text
  color: primary
  font-size: 14px

  p
    margin-bottom: vertical-rhythm
    margin-top: 0
    position: relative

    &:last-child, &:only-child
      margin-bottom: 0

  a
    color: general-link
    word-break: break-all
    &:hover
      text-decoration: none

  img
    max-width: 100%
