// inherits from pop-overs.styl

.project-pop
  .project-avatar-image
    width: 21%
    border-radius: 3px
    float: left
    cursor: pointer
  .project-info-container
    position: relative
    overflow: auto
  .project-info-meta
    float: left
    width: 75%
    margin-left: 4%
  .project-name
    font-weight: bold
  .project-name-loader
    position: absolute
    right: 0
  .project-avatar-loader
    float: left
    width: 21%
    .loader
      width: 30px
      height: 30px
  .private-icon,
  .public-icon
    background-position-y: -1px
    margin-right: 5px
  .public-icon
    dulled-secondary()
