// GitHub-flavored emoji
// add new rules as you go
// reference: https://github.com/muan/emoji

emojiPath = '../images/emojis/'
fire_engine = emojiPath + 'fire_engine.png' // used by inputs.styl
raisedHand = emojiPath + 'raised-hand.png'

.button
  .emoji:hover
    background-color: transparent

.emoji
  display: inline-block
  background-repeat: no-repeat
  background-position: center
  vertical-align: middle
  width: 18px
  height: 18px
  background-size: contain

  &.ambulance
    background-image: url(emojiPath + 'ambulance.png')
  &.arrow-up
    background-image: url(emojiPath + 'arrow-up.png')
  &.arrow-backward
    background-image: url(emojiPath + 'arrow-backward.png')
  &.balance-scale
    background-image: url(emojiPath + 'balance-scale.png')
  &.balloon
    background-image: url(emojiPath + 'balloon.png')
  &.bento
    background-image: url(emojiPath + 'bento.png')
  &.bomb
    background-image: url(emojiPath + 'bomb.png')
  &.bouquet
    background-image: url(emojiPath + 'bouquet.png')
  &.dog
    background-image: url(emojiPath + 'dog.png')
  &.carp-streamer
    background-image: url(emojiPath + 'carp-streamer.png')
  &.chart-with-upwards-trend
    background-image: url(emojiPath + 'chart-with-upwards-trend.png')
  &.clap
    background-image: url(emojiPath + 'clap.png')
  &.clock3
    background-image: url(emojiPath + 'clock3.png')
  // &.coffee
  //   background-image: url(emojiPath + 'coffee.png')
  // &.confetti-ball
  //   background-image: url(emojiPath + 'confetti-ball.png')
  // &.credit-card
  //   background-image: url(emojiPath + 'credit-card.png')
  // &.crown
  //   background-image: url(emojiPath + 'crown.png')
  // &.construction-worker
  //   background-image: url(emojiPath + 'construction-worker.png')
  &.crescent-moon
    background-image: url(emojiPath + 'crescent-moon.png')
  &.crystal-ball
    background-image: url(emojiPath + 'crystal-ball.png')
  &.cyclone
    background-image: url(emojiPath + 'cyclone.png')

  &.dancers
    background-image: url(emojiPath + 'dancers.png')
  &.eyeglasses
    background-image: url(emojiPath + 'eyeglasses.png')
  &.earth-asia
    background-image: url(emojiPath + 'earth-asia.png')
  // &.envelope
  //   background-image: url(emojiPath + 'envelope.png')
  &.email
    background-image: url(emojiPath + 'email.png')
  &.eyes
    background-image: url(emojiPath + 'eyes.png')
  // &.ice-cream
  //   background-image: url(emojiPath + 'ice_cream.png')
  &.female-technologist

    background-image: url(emojiPath + 'female-technologist.png')
  &.ferris-wheel
    background-image: url(emojiPath + 'ferris-wheel.png')
  &.firebase
    background-image: url("../images/logos/firebase.svg")
  &.framed-picture
    background-image: url(emojiPath + 'framed-picture.png')

  &.ghost
    background-image: url(emojiPath + 'ghost.png')
  &.globe-with-meridians
    background-image: url(emojiPath + 'globe-with-meridians.png')
  &.google
    background-image: url(emojiPath + 'google.png')
    width: 15px
  &.hamburger
    background-image: url(emojiPath + 'hamburger.png')
  &.heart-eyes
    background-image: url(emojiPath + 'heart-eyes.png')
  &.helmet-with-white-cross
    background-image: url(emojiPath + 'helmet-with-white-cross.png')
  &.hibiscus
    background-image: url(emojiPath + 'hibiscus.png')

  // &.information-source
  //   background-image: url(emojiPath + 'information-source.png')

  &.raising-hand-woman
    background-image: url(emojiPath + 'raising-hand-woman.png')

  &.lady-beetle
    background-image: url(emojiPath + 'lady-beetle.png')
  &.last-quarter-moon-with-face
    background-image: url(emojiPath + 'last-quarter-moon-with-face.png')
  &.lock
    background-image: url(emojiPath + 'lock.png')
  &.love-letter
    background-image: url(emojiPath + 'love-letter.png')
  &.love-hotel
    background-image: url(emojiPath + 'love-hotel.png')
  // &.map-of-japan
  //   background-image: url(emojiPath + 'map-of-japan.png')
  &.microphone
    background-image: url(emojiPath + 'microphone.png')
  // &.minidisc
  //   background-image: url(emojiPath + 'minidisc.png')
  &.musical-keyboard
    background-image: url(emojiPath + 'musical-keyboard.png')

  // &.notes
  //   background-image: url(emojiPath + 'notes.png')
  &.newspaper
    background-image: url(emojiPath + 'newspaper.png')
  &.octocat
    background-image: url(emojiPath + 'github-logo-' + theme-type +'.svg')
  &.octagonal-sign
    background-image: url(emojiPath + 'octagonal-sign.png')
  &.facebook
    background-image: url(emojiPath + 'facebook.png')
    width: 15px

  &.palm-tree
    background-image: url(emojiPath + 'palm-tree.png')
  &.pager
    background-image: url(emojiPath + 'pager.png')
  &.paperclip
    background-image: url(emojiPath + 'paperclip.png')

  &.rainbow
    background-image: url(emojiPath + 'rainbow.png')
  &.raised-hand
    background-image: url(emojiPath + 'raised-hand.png')
  &.repeat
    background-image: url(emojiPath + 'repeat.png')
  &.rewind
    background-image: url(emojiPath + 'rewind.png')
  &.rose
    background-image: url(emojiPath + 'rose.png')
  // &.rotate
  //   background-image: url(emojiPath + 'rotate.png')
  &.see-no-evil-monkey
    background-image: url(emojiPath + 'see-no-evil-monkey.png')
  &.seedling
    background-image: url(emojiPath + 'seedling.png')
  &.selfie
    background-image: url(emojiPath + 'selfie.png')
  &.sparkles
    background-image: url(emojiPath + 'sparkles.png')
  &.sparkle
    background-image: url(emojiPath + 'sparkle.png')
  &.sparkling-heart
    background-image: url(emojiPath + 'sparkling-heart.png')
  &.splashing-sweat
    background-image: url(emojiPath + 'splashing-sweat.png')
  &.sunglasses
    background-image: url(emojiPath + 'sunglasses.png')
  &.sunny
    background-image: url(emojiPath + 'sunny.png')
  // &.snowman
  //   background-image: url(emojiPath + 'snowman.png')

  &.traffic-light
    background-image: url(emojiPath + 'traffic-light.png')
  &.truck
    background-image: url(emojiPath + 'truck.png')
  &.turtle
    background-image: url(emojiPath + 'turtle.png')
  &.two-women-holding-hands
    background-image: url(emojiPath + 'two-women-holding-hands.png')
  &.thumbsup
    background-image: url(emojiPath + 'thumbsup.png')
  &.tokyo-tower
    background-image: url(emojiPath + 'tokyo-tower.png')
  &.two
    background-image: url(emojiPath + 'two.png')

  &.umbrella
    background-image: url(emojiPath + 'umbrella.png')

  &.wave
    background-image: url(emojiPath + 'wave.png')
  // &.writing-hand
  //   background-image: url(emojiPath + 'writing-hand.png')
  // &.unlock
  //   background-image: url(emojiPath + 'unlock.png')

  &.avatar
    width: 18px
    height: 18px

// 💁 add new emojis to emoji-cache.jade
.emojiCache
  visibility: hidden
  height: 0
  width: 0
  user-select: none
  .emoji
    position: absolute
    visibility: hidden
    height: 0
    width: 0
    user-select: none
