// inherits from overlays.styl

.drag-to-upload
  border: none
  background-color: transparent
  overflow: hidden
  box-shadow: none
  p
    font-size: 18px
    font-weight: bold
    text-align: center
    margin-top: 0
    margin-bottom: 0.5em
  .emoji
    width: 30px
    height: 30px
    display: block
    margin-left: auto
    margin-right: auto
