// inherits from pop-overs.styl

.share-buttons-pop
  left: 3px
  top: 15px

  .share-button-image-wrapper
    padding: half-vertical-rhythm
    margin-bottom: vertical-rhythm
    background-color: #fff

    img
      display: block
