.logs-panel
  display: flex
  z-index: 0

  header
    z-index: 2

  .logs-scroll
    overflow-y: scroll
    flex: 1

  .logs-container
    font-family: monospace
    font-size: 12px
    margin: 8px 0

  pre
    word-break: break-all
    word-wrap: break-word
    white-space: pre-wrap
    font-family: inherit
    font-size: inherit
    margin: 0
    flex: 1

  .log-time
    color: text-on-secondary-background
    padding-left: 5px
    opacity: 0.4
    user-select: none

  .log-item
    display: flex
    align-items: center
    padding: 4px side-padding

    &.has-jump-button
      padding: 2px side-padding

    &:hover
      .log-time
        opacity: 1
      background-color: secondary-background-hover

    button.text
      font-weight: normal
      color: inherit
      height: auto
      padding: 0
      margin: 0
      text-decoration: underline
      background-color: transparent

      &:hover
        text-decoration: none

  .jump-to-button
    margin-right: side-padding

  .jump-line
    color: inherit
    background-color: transparent

    &:hover
      color: inherit

  .file-path
    text-decoration: underline
    cursor: pointer
    &:hover
      text-decoration: none

  // .run-log
  //   color: file-info

  .success-log
    color: logs-panel-success

  .error-log
    color: logs-panel-errors

  .sleep-log
    color: text-on-secondary-background

  .instance-divider
    width: 100%
    overflow: auto
    display: flex

    hr
      background-color: line-on-secondary-background
      height: 1px
      border: 0
      flex: 1

    .relative-time
      text-align: right
      opacity: 1

  .loader
    position: relative
    bottom: 2px
    width: 13px
    height: 12px
    margin-right: 2px

  .button-debugger
    .loader
        height: 12px
        width: 12px
        vertical-align: bottom
        margin-top: 4px

    .down-arrow
      vertical-align: text-top
    &:hover
      .down-arrow
        opacity: 1
      .usage-label
        color: primary

    progress
      margin-top: 11px
      width: 40px
      display: inline-block
      border: 1px solid line-on-secondary-background
      &::-webkit-progress-value
        background-color: line-on-secondary-background
      &::-moz-progress-bar
        background-color: line-on-secondary-background
    .unstable
      border: 1px solid warning
      &::-webkit-progress-value
        background-color: warning
      &::-moz-progress-bar
        background-color: warning
    .stopped
      border: 1px solid error
      &::-webkit-progress-value
        background-color: error
      &::-moz-progress-bar
        background-color: error
