.welcome-message
  font-size: 14px
  color: primary
  text-align: center

.welcome-message-with-image
  flex: 1
  flex-direction: column
  justify-content: center
  display: flex
