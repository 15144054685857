// inherits from pop-overs.styl

.add-package-pop
  left: 15px
  top: 33px
  .packages-list-loading
    margin-top: vertical-rhythm
    margin-bottom: vertical-rhythm
  .no-results
    margin-left: half-vertical-rhythm
    margin-top: half-vertical-rhythm
  .result-description
    width: 80%
