@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

 @keyframes jiggle
  0%
    z-index: 2
    transform: rotate(0deg)
  33%
    transform: rotate(3.5deg)
  66%
    transform: rotate(-2deg)
  100%
    z-index: 2
    transform: rotate(0deg)
