.button,
button:not(.no-button-styles)
  text-align: left
  color: primary
  border: 2px solid primary
  user-select: none
  display: inline-flex
  align-items: center
  text-decoration: none
  cursor: pointer
  background-color: primary-background
  border-radius: 5px
  font-family: sans-serif
  padding-top: 2px
  padding-bottom: 1px

  &:hover
    background-color: secondary-background
  &:active,
  &:focus
    background-color: secondary-background-active
    outline: none
  &:hover,
  &:active,
  &:focus
    .icon
      opacity: 1
  &.active
    background-color: secondary-background-active
    color: primary
    .icon
      opacity: 1
  .status-badge
    margin-right: 0
    font-size: 11px
    .status
      margin-left: 5px
      margin-bottom: 0
      margin-top: -2px
      text-transform: capitalize
  .loader
    margin-left: 5px
    height: 1em
    width: 1em
    vertical-align: -3px
  .ask-for-help
    width: 21px
    height: 19px
  .emoji
    cursor: pointer
    margin-left: 7px
    flex-shrink: 0
  .avatar
    width: 18px
    height: 18px
    background-repeat: no-repeat
    display: inline-block
    margin-left: 3px
    margin-top: -1px
    vertical-align: middle
  .anon-avatar
    background-position: center
    background-size: 14px
  .icon
    flex-shrink: 0
  .rewind
    margin-left: 7px
  .text-icon
    margin-left: 7px

  &[disabled]
    opacity: .5
    pointer-events: none
    cursor: pointer
  a
    color: primary

.disabled
  opacity: .5
  pointer-events: none
  cursor: pointer

.button-wrap
  display: block
  margin-bottom: vertical-rhythm
  &:last-child
    margin-bottom: 0
  a
    text-decoration: none
  label
    padding-top: 6px
    .emoji
      margin-top: -3px !important

button-large()
  font-weight: 600
  font-size: 14px
  padding-left: 12px
  padding-right: 12px
  height: 32px
  .loader
    display: inline-block
    width: 18px
    height: 18px
    margin: 0
    margin-left: 2px

button-small()
  border-radius: 5px
  font-weight: 600
  font-size: 12px
  height: 25px
  padding-left: 8px
  padding-right: 8px
  .loader
    vertical-align: -3px
  .emoji
    width: 16px
    height: 16px
  &:not(.no-button-styles) .team-avatar
    width: 16px
    height: 16px
    margin-top: -2px

button-secondary-on-secondary-background()
  border-width: 1px
  color: text-on-secondary-background
  border-color: line-on-secondary-background
  background-color: secondary-background
  &:hover,
  &:focus
    color: primary
    background-color: secondary-background-hover
  &:active,
  &.active
    color: primary
    background-color: secondary-background-active
    border-color: primary

button-secondary()
  border-width: 1px
  color: text-on-secondary-background
  border-width: 1px
  border-color: line-on-secondary-background
  &:hover,
  &:focus
    color: primary
    border-color: line-on-secondary-background
  &:active
    border-color: primary
  &.active
    color: primary
  .down-arrow
    vertical-align: initial
    margin-left: 3px
    margin-right: 0

segmented-buttons()
  display: flex
  button
    button-small()
    &:first-child
      border-top-right-radius: 0
      border-bottom-right-radius: 0
    &:last-child
      border-left-width: 0
      border-top-left-radius: 0
      border-bottom-left-radius: 0
    &:not(:first-child):not(:last-child)
      border-radius: 0px
      border-left-width: 0

    // When marked as active, show the left border in case it gets highlighted
    // by another style (e.g. button-secondary-on-secondary-background)
    &:not(:first-child)
      &:active,
      &.active
        border-left-width: 1px
        margin-left: -1px

button-copy()
  button-small()
  button-secondary()
  top: -2px
  right: 0
  position: absolute
  padding: 2px 4px
  font-weight: normal
  z-index: 1

select
  appearance: none
  position: relative
  cursor: pointer

.close
  dulled-secondary()
  padding: 10px
  user-select: none
  transition: right 0.1s
  hover-highlight()
  position: absolute
  right: side-padding
  transform: translateY(2px)

.button-ask-for-help.button-no-styles
  position: relative
  padding: 4px 6px
  margin-top: -6px
  border-width: 1px
  border-color: #ccc
  user-select: none

.button-email-sign-in
  margin-top: 10px

.fade-in
  animation: fadeIn .2s ease-out

.button-large
  font-weight: 600
  font-size: 14px
  padding-left: 12px
  padding-right: 12px
  height: 32px

.button-small
  border-radius: 5px
  font-weight: 600
  font-size: 12px
  height: 25px
  padding-left: 8px
  padding-right: 8px

.button-cta,
button.button-cta
  color: primary
  font-weight: 600
  cursor: pointer
  background-color: cta
  box-shadow: 4px 4px 0 primary
  &:hover
    background-color: cta
    box-shadow: 2px 2px 0 primary
  &:active
    background-color: cta
    box-shadow: none

.no-button-styles
  -webkit-appearance: none
  border: none
  padding: unset
  background: unset

  &:active
    outline: none
