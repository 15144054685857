// inherits from pop-overs.styl

.person-pop
  .online-active-user:after
    content: ''
    width: 8px
    height: 8px
    border-radius: 50%
    background: success
    float: left
    position: relative
    top: 3px
    margin-right: 4px

  .profile-description
    img
      border-radius: 5px

  .person-pop-timezone
    float: right
    text-decoration: none

  .thanks-count
    margin-top: vertical-rhythm
