// inherits from pop-overs.styl

.tools-pop
  bottom: 23px
  left: 2px
  top: initial
  .loader
    width: 16px
    height: 16px
    margin-left: 5px
