.project-info
  padding-left: side-padding
  padding-right: side-padding
  padding-bottom: 10px
  border-bottom: 1px solid section-line
  position: relative
  clear: both
  max-width: 100%

  .project-avatar
    cursor: pointer
    margin-right: 4px

    .avatar-image
      width: 34px
      border-radius: 3px
      vertical-align: middle
      position: relative
