sparklesDuration = 1.5s
firstSparkleDelay = 0.35s
secondSparkleDelay = 0.65s

.asking-for-help-loader
  position: relative;

  .icon.ask-for-help
    width: 21px
    height: 19px

  .sparkles
    position: absolute
    top: 0
    left: 2px

    .sparkle
      position: absolute
      .sparkle-child
        content: ''
        display: block
        animation: sparkleY sparklesDuration ease-out infinite
        width: 6px
        height: 6px
        border-radius: 10px
      &:first-child
        animation: sparkleXLeftLeft sparklesDuration ease-out infinite
        animation-delay: firstSparkleDelay
        .sparkle-child
          animation-delay: firstSparkleDelay
      &:nth-child(2)
        animation: sparkleXLeftRight sparklesDuration ease-out infinite
        animation-delay: secondSparkleDelay
        .sparkle-child
          animation-delay: secondSparkleDelay
      &:last-child
        animation: sparkleXLeft sparklesDuration ease-out infinite

    .middle
      .sparkle
        &:first-child
          animation: sparkleXMiddleLeft sparklesDuration ease-out infinite
          animation-delay: firstSparkleDelay
          .sparkle-child
            animation-delay: firstSparkleDelay
        &:nth-child(2)
          animation: sparkleXMiddleRight sparklesDuration ease-out infinite
          animation-delay: secondSparkleDelay
          .sparkle-child
            animation-delay: secondSparkleDelay
        &:last-child
          animation: sparkleXMiddle sparklesDuration ease-out infinite

    .right
      .sparkle
        &:first-child
          animation: sparkleXRightRight sparklesDuration ease-out infinite
          animation-delay: firstSparkleDelay
          .sparkle-child
            animation-delay: firstSparkleDelay
        &:nth-child(2)
          animation: sparkleXRightLeft sparklesDuration ease-out infinite
          animation-delay: secondSparkleDelay
          .sparkle-child
            animation-delay: secondSparkleDelay
        &:last-child
          animation: sparkleXRight sparklesDuration ease-out infinite

sparkleHeight = -45px
sparkleVariance = 10px
sparkleLeft = -40px
sparkleMiddle = 0px
sparkleRight = 40px

@keyframes sparkleY
  60%
    opacity: 1
    width: 6px
    height: 6px
  75%
    opacity: 0.5
    width: 8px
    height: 8px
  85%
    opacity: 0.8
  100%
    opacity: 0
    transform: translateY(sparkleHeight)
    width: 5px
    height: 5px

@keyframes sparkleXLeft
  to
    transform: translateX((sparkleLeft))
@keyframes sparkleXLeftLeft
  to
    transform: translateX(sparkleLeft - sparkleVariance)
@keyframes sparkleXLeftRight
  to
    transform: translateX(sparkleLeft + sparkleVariance)

@keyframes sparkleXMiddle
  to
    transform: translateX(sparkleMiddle)
@keyframes sparkleXMiddleLeft
  to
    transform: translateX(sparkleMiddle - sparkleVariance)
@keyframes sparkleXMiddleRight
  to
    transform: translateX(sparkleMiddle + sparkleVariance)

@keyframes sparkleXRight
  to
    transform: translateX(sparkleRight)
@keyframes sparkleXRightLeft
  to
    transform: translateX(sparkleRight - sparkleVariance)
@keyframes sparkleXRightRight
  to
    transform: translateX(sparkleRight + sparkleVariance)
